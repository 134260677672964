.navbar {
  box-sizing: border-box;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  @media print {
    display: none;
  }
}

.page {
  margin: 95px 24px 0;
  @media print {
    margin: 0;
  }
}
.pageWithHero {
  margin: 8px 24px;
  @media print {
    margin: 0;
  }
}

.heroImage {
  width: 100%;
  height: 40vh; // 40% of viewport, but the navbar covers part of it
  overflow: hidden;
  img {
    width: 100%;
    margin-top: -50%; // so that we see the middle of the image
  }
}
