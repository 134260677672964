.imagePreivewWrapper {
  display: inline-block;
  margin-bottom: 4px;
}
.hidden {
  display: none;
}

.full {
  max-width: 100%;
}
