$roundy: 27px;

.qrReader {
  width: 100%;
  max-width: 500px;
  margin: auto;
  border-radius: $roundy;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 1.2);
  > section {
    border-radius: $roundy;
    > div {
      z-index: 0;
    }
  }
}
