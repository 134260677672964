.printOnly {
  display: none;
  @media print {
    display: block;
  }
}

.noPrint {
  @media print {
    display: none;
  }
}
