$margin: 4px;

.imagePreview {
  display: inline-block;
  position: relative;
  img {
    max-width: 200px;
    max-height: 200px;
    margin: $margin;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  span {
    position: absolute;
    margin: $margin + 4px;
    padding: 2px;
    top: 0;
    left: 0;
    background: #ddd;
    border-radius: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}
