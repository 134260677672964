.big {
  font-size: 32px;
}
.kindaBig {
  font-size: 20px;
}

.centre {
  text-align: center;
}

.link {
  margin: 8px;
}
